<template>
  <div class="layout-vertical layout-center padding-top-large" style="max-width: 500px;">
    <template v-if="procedure">
      <div class="font-size-large font-color-placeholder margin-top-large">管理用户的干预流程</div>
      <ys-button size="large" lighting class="margin-top-large" @click="clickStop">
        <span class="fas fa-stop"></span>
        <span class="margin-left-small">停止干预</span>
      </ys-button>
    </template>
    <template v-else>
      <ys-button size="large" lighting class="margin-top-large"
                 @click="clickStart">
        <span class="fas fa-play"></span>
        <span class="margin-left-small">开始干预</span>
      </ys-button>
    </template>
    <scheme-picker-dialog
        :visible.sync="schemePickerDialogVisible"
        :organization-id="organizationId"
        @pick="handleSchemePicked"
    ></scheme-picker-dialog>
    <delete-dialog
        :visible.sync="stopDialogVisible"
        title="停止干预"
        message="您确定要停止该用户的营养干预吗？"
        confirm-button-text="停止"
        @confirm="confirmStop"
    ></delete-dialog>

  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import YsButton from "@/components/wedigets/YsButton";
import SchemePickerDialog from "@/components/dialog/SchemePickerDialog";
import DeleteDialog from "@/components/dialog/DeleteDialog";

export default {
  name: "ProcedurePanel",
  components: {DeleteDialog, SchemePickerDialog, YsButton},
  mixins: [httpapi],
  props: {
    userId: Number,
    organizationId: Number
  },
  data() {
    return {
      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 干预流程
       */
      procedure: null,

      /**
       * 开始干预
       */
      schemePickerDialogVisible: false,

      /**
       * 停止干预
       */
      stopDialogVisible: false,
    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.procedure = null;
        this.loadProcedure();
      },
      immediate: true,
    }
  },
  methods: {
    clickStart: function () {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.schemePickerDialogVisible = true;
    },
    handleSchemePicked: function (schemeList) {
      this.startProcedure(schemeList[0]);
    },
    clickStop: function () {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.stopDialogVisible = true;
    },
    confirmStop: function () {
      this.stopProcedure();
    },
    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },
    loadProcedure: function () {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;
      this.$reqGet({
        path: `/procedure/${this.userId}/${this.organizationId}`,
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.procedure = res.data;
          })
          .catch(() => this.$message.error('加载失败'))
          .complete(() => this.busyLoadingCode = 0);
    },
    startProcedure: function (scheme) {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;
      this.$reqPost({
        path: `/procedure/${this.organizationId}/${scheme.id}/${this.userId}`,
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.procedure = res.data;
          })
          .catch(() => this.$message.error('操作失败'))
          .complete(() => this.busyLoadingCode = 0);
    },
    stopProcedure: function () {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;
      this.$reqDelete({
        path: `/procedure/${this.procedure.id}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            this.procedure = null;
          })
          .catch(() => this.$message.error('操作失败'))
          .complete(() => this.busyLoadingCode = 0);
    }
  }
}
</script>

<style scoped>

</style>